<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-muted justify-content-end small">
        <li class="breadcrumb-item">
          {{ $t("id") }}:
          <span class="badge bg-secondary">{{ formEdit.id }}</span>
        </li>
        <li class="breadcrumb-item">
          {{ $t("dateInsert") }}:
          <span class="badge bg-secondary" v-if="moment">
            {{ moment(formEdit.created_at).format("DD/MM/YYYY") }}
          </span>
        </li>
      </ol>
    </nav>

    <div class="col-12 mb-3">
      <label for="titulo" class="form-label">Título</label>
      <input
        type="text"
        class="form-control"
        v-model="formEdit.titulo"
        aria-describedby="titulo"
      />
    </div>
    <div class="col-12 mb-3">
      <label for="subtitulo" class="form-label">Sub Título</label>
      <input
        type="text"
        class="form-control"
        v-model="formEdit.subtitulo"
        aria-describedby="subtitulo"
      />
    </div>
    <div class="col-12 mb-3">
      <label for="conteudo" class="form-label">Conteúdo</label>
      <TiptapVr v-model="formEdit.conteudo"> </TiptapVr>
    </div>

    <div class="card p-2 mb-3">
      <div class="mb-3">
        <div class="row">
          <label for="image_path" class="form-label">Imagem / Capa</label>
          <img
            :src="urlImg"
            :key="formEdit.image_path"
            class="img-fluid img-thumb-200"
          />
        </div>
      </div>
      <div class="mb-3" v-if="!deleteMode">
        <input
          ref="pathimagem"
          type="file"
          class="form-control"
          id="path_input"
          accept="image/*"
          @change="sendImagem()"
        />
        <div id="emailHelp" class="form-text">
          <small> Formato da Imagem: <b>.png, .jpg, .jpeg</b></small>
        </div>
      </div>
    </div>
    <!-- /// -->
    <div class="card p-2 mb-3">
      <div class="mb-3">
        <div class="row">
          <label for="pdf_path" class="form-label">PDF (Arquivo com detalhamentos)</label>
          <a :href="urlPdf" :key="formEdit.pdf_path"></a>
        </div>
        <a v-if="formEdit.pdf_path" :href="urlPdf" :key="formEdit.pdf_path" class="btn btn-info col-4"
          ><i class="bi bi-download"></i> {{ $t("downPdf") }}</a
        >
      </div>
      <div class="mb-3" v-if="!deleteMode">
        <input
          ref="pathpdf"
          type="file"
          accept=".pdf"
          class="form-control"
          @change="sendPdf()"
        />
        <div id="emailHelp" class="form-text">
          <small> Formato da Arquivo: <b>.pdf</b></small>
        </div>
      </div>
    </div>

    <div class="col-12 mb-3" v-if="!deleteMode">
      <label for="lang_input" class="form-label">{{ $t("status") }}</label>
      <select
        class="form-select"
        @change="formEdit.status = $event.target.value"
        v-model="formEdit.status"
      >
        <option value="1">Ativo</option>
        <option value="0">Inativo</option>
      </select>
    </div>

    <div class="d-grid gap-2 mb-4 mt-4">
      <button
        class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
        type="button"
        v-if="!loading"
        @click="sendEdit"
      >
        {{ $t("submit") }} <i class="bi bi-save"></i>
      </button>
      <button
        class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
        type="submit"
        v-if="loading"
        disabled
      >
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ $t("loading") }}
      </button>
    </div>
  </div>
</template>

<script>
import { URL_IMAGE } from "../../../../../services/Commons";
import ArtigoService from "../../../../../services/artigo.service";
import ArtigoHandle from "../../../../../services/handle/artigo.handle";
import TiptapVr from "../../../../../components/TipTapVr";
import moment from "moment";
export default {
  props: [
    "dataItem",
    "actionSend",
    "ActionSendFile",
    "errorServer",
    "deleteMode",
  ],
  data: () => ({
    formEdit: {},
    urlImg: "",
    urlPdf: "",
    loading: false,
    moment: null,
  }),
  mounted() {
    this.moment = moment;
    this.moment.locale("pt-br");
    this.urlImg = URL_IMAGE + `artigodowimagem/${this.dataItem.id}/`;
    this.urlPdf = URL_IMAGE + `artigodowpdf/${this.dataItem.id}/`;
    this.formEdit = this.dataItem;
    //console.log(this.formEdit);
  },
  components: {
    TiptapVr,
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      //
      if (this.deleteMode) {
        ArtigoService.delete(this.formEdit.id).then(
          (result) => {
            console.log(result);
            this.loading = false;
            this.actionSend({
              msg: "O item foi excluído com Sucesso.",
              style: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
        return;
      }
      ArtigoService.update(
        this.formEdit.id,
        ArtigoHandle.formToJso(this.formEdit)
      ).then(
        (result) => {
          //console.log(result);
          result;
          this.loading = false;
          this.actionSend({
            msg: "O item foi alterado com Sucesso.",
            style: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.errorServer(error);
        }
      );
    },
    sendImagem: function (field) {
      this.loading = true;
      if (this.$refs["pathimagem"].files[0]) {
        let formData = new FormData();
        formData.append("file", this.$refs["pathimagem"].files[0]);
        ArtigoService.sendFileImg(this.formEdit.id, formData).then(
          (result) => {
            this.formEdit[field] = result.data;
            this.loading = false;
            this.ActionSendFile();
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
      }
    },
    sendPdf: function (field) {
      this.loading = true;
      if (this.$refs["pathpdf"].files[0]) {
        let formData = new FormData();
        formData.append("file", this.$refs["pathpdf"].files[0]);
        ArtigoService.sendFilePdf(this.formEdit.id, formData).then(
          (result) => {
            this.formEdit[field] = result.data;
            this.loading = false;
            this.ActionSendFile();
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
      }
    },
  },
};
</script>

<style>
</style>
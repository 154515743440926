import axios from 'axios';
import authHeader from './auth-header';
import authHeaderFile from './auth-header-file';

import { URL_API } from './Commons';

class ArtigoService {

  async getAll() {
    return await axios.get(URL_API + 'artigos', { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + 'artigo/' + id, { headers: authHeader() });
  }

  insert(artigo) {
    return axios.post(URL_API + 'artigo', artigo, { headers: authHeader() });
  }

  update(id, artigo) {
    return axios.put(URL_API + 'artigo/' + id, artigo, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + 'artigo/' + id, { headers: authHeader() });
  }

  sendFileImg(id, formData) {
    return axios.post(URL_API + 'artigoupimagem/' + id, formData, { headers: authHeaderFile() });
  }

  sendFilePdf(id, formData) {
    return axios.post(URL_API + 'artigouppdf/' + id, formData, { headers: authHeaderFile() });
  }

}

export default new ArtigoService();